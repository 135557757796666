import FileSaver from "file-saver";

//照片上传防抖
export function simpleDebounce(fn, delay = 100) {
  let timer = null
  return function () {
    let args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  }
}

//打印
export function printExcel(id) {
  let printStr =
    "<html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'></head>";
  const tabStyle = `<style>
  .el-table__header,.el-table__body{width: 100% !important;}
  table {border-top: 1px solid #ccc;border-collapse: collapse;text-align: center;width: 100vw;box-sizing: border-box;}
  td,th {border-bottom: 1px solid #ccc;border-right: 1px solid #ccc;}
  img,.el-button,.last_hidden {display: none;}
  .cell {width: 100px; word-wrap: break-word !important;}
  .is-leaf {width: 7.69%;}
  .el-table__row td {width: 7.69%;}
  </style><body>`;
  let content = "";
  let fix = document.querySelector(".el-table__fixed");
  let str = document.getElementById(id).removeChild(fix).innerHTML;
  document.getElementById(id).appendChild(fix)
  content = content + str;
  printStr = printStr + tabStyle + content + "</body></html>";
  // 打开新页面
  let pwin = window.open("_blank");
  // 将内容赋值到新页面
  pwin.document.write(printStr);
  pwin.document.close();
  // 聚焦-不加focuse，在某些情况下，打印页面会有问题。
  pwin.focus();
  // 使用setTimeout，等页面dom元素渲染完成后再打印。
  setTimeout(() => {
    pwin.print(); // 打印功能。 例如 window.print() 直接打印当前整个页面。
    pwin.close(); // 关闭 打印创建的当前页面
  }, 500);
}

// 导出表格
export function excelExport(id) {
  let fix = document.querySelector(".el-table__fixed");
  // let last_hidden_header=document.querySelector(".last_hidden")
  // let last_hidden_body=document.querySelector(".el-table__row .last_hidden")
  let wb;
  const XLSX = require("xlsx");
  // 设置导出的内容是否只做解析，不进行格式转换     false：要解析， true:不解析
  const xlsxParam = { raw: true };
  if(fix){
    wb = XLSX.utils.table_to_book(
      document.getElementById(id).removeChild(fix),
      xlsxParam
    );
    document.getElementById(id).appendChild(fix);
  }else{
    wb = XLSX.utils.table_to_book(
      document.getElementById(id),
      xlsxParam
    );
  }
  // 导出excel文件名
  const fileName = "订单列表" + new Date().getTime() + ".xlsx";

  const wbout = XLSX.write(wb, {
    bookType: "xlsx",
    bookSST: true,
    type: "array",
  });
  try {
    // 下载保存文件
    FileSaver.saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      fileName
    );
  } catch (e) {
    if (typeof console !== "undefined") {
      // console.log(e, wbout);
    }
  }
  return wbout;
}

export function formatDate(){
  const dt=new Date()
  const hh=(dt.getHours()+'').padStart(2,'0')
  const mm=(dt.getMinutes()+'').padStart(2,'0')
  const ss=(dt.getSeconds()+'').padStart(2,'0')
  return `${hh}:${mm}:${ss}`
}

export function compareDateTime(date){
  const dt=new Date()
  const usedate=new Date(date)
  return dt===usedate?0:(dt<usedate?1:-1)
}